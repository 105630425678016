@feature7: feature7;

.@{feature7} {
	&-wrapper {
		min-height: 564px;
		margin: 0 auto;
		overflow: hidden;
		background-color: #ffffff;

		&.home-page-wrapper {
			.home-page {
				padding: 64px 24px;
			}
		}
	}

	&-title {
		&-wrapper {
			text-align: center;
			margin-bottom: 40px;
		}

		&-h1 {
			font-size: 32px;
			color: #0050ff;
		}

		&-content {
			margin-top: 16px;
		}
	}

	&-block {
		margin-top: 28px;

		&-group {
			display: block;
			padding: 28px 24px;
			box-shadow: 0 2px 16px fade(#000, 8);
			background-image: url('https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*fMOFSpRXMxsAAAAAAAAAAABkARQnAQ');
			background-repeat: no-repeat;
			background-position: 100% 100%;
			transition: box-shadow @animate-duration @ease-in-out, transform @animate-duration @ease-in-out;

			&:hover {
				transform: translateY(-5px);
				box-shadow: 0 6px 16px fade(#000, 12);
			}
		}

		&-image {
			float: left;
			width: 24px;
		}

		&-title {
			font-size: 16px;
			float: left;
			// margin-left: 8px;
			margin-bottom: 16px;
			color: #0050ff;
			font-weight: 600;
		}

		&-content {
			clear: both;
			color: fade(@text-color, 75);
		}
	}
}

@media screen and (max-width: 767px) {
	.@{feature7}-wrapper {
		min-height: 1540px;
		&.home-page-wrapper {
			.home-page {
				padding: 56px 24px;
			}
		}
	}
}
