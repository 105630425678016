@banner4: banner4;

.@{banner4} {
	// 如果在第一屏且导航位置为 relative, 一屏为 height: calc(~"100vh - 64px");
	width: 100%;
	// height: 100vh;
	padding: 80px 0px 80px 0px;
	position: relative;
	text-align: left;
	background: #4b5564;
	overflow: hidden;

	& &-page {
		padding: 1px 0 0;
		display: flex;
		align-items: center;
	}

	&-title-wrapper {
		margin-top: 64px;
	}

	&-title {
		color: #0050ff;
		font-size: 48px;
		line-height: 1.5;
	}

	&-content {
		font-size: 14px;
		line-height: 20px;
		// color: #fff;
		margin: 8px auto 16px;
	}

	&-image,
	&-image-mobile {
		width: 80%;
		max-height: 60%;
		overflow: hidden;
		margin: auto;
		// position: absolute;
	}
}

@media screen and (max-width: 767px) {
	.@{banner4} {
		height: 500px;
		& &-page.home-page {
			padding-top: 1px;
			padding-bottom: 0;
		}

		&-title-wrapper {
			margin-top: 96px;
		}
		&-title {
			font-size: 3em;
		}
		&-image {
			width: 100%;
			padding: 0 24px;
		}
	}
}
