#Banner4_0 .home-page > .kh4mur82uji-editor_css {
	margin: 0;
}
#Banner4_0 .home-page > .kh4mspcpte-editor_css {
	margin: auto auto;
	padding-left: 70px;
}
#Banner4_0 .home-page > .banner4-title-wrapper > .kh4s9pt5gn-editor_css {
	margin: 0px 0 0em;
}
#Banner4_0 .home-page > .banner4-title-wrapper > .kh4q3i6ukga-editor_css {
	color: #5e5e5e;
	margin: 0 0 20px;
	font-weight: 700;
	font-size: 23px;
}
#Feature4_0 .ant-col > .title-wrapper > .kh4sdn34537-editor_css {
	margin: 0px 0px;
}
#Feature4_0 .ant-row > .ant-col > .kh4sec9mrfu-editor_css {
	margin: 0px auto 20px;
}
#Banner4_0.kh4s9iy6zg-editor_css {
	background-color: #f7f9fc;
}
#Nav0_0.kh4si7loilj-editor_css {
	background-color: #ffffff;
}
#Nav0_0 .header0-menu > .ant-menu > .kh4sm6a7qxs-editor_css {
	display: block;
}
#Nav0_0 .home-page > .kh4sisf7zv9-editor_css {
	background-color: rgba(13, 107, 197, 0);
}
#Nav0_0 .header0-menu > .ant-menu > .kh4sp4op8fg-editor_css {
	background-color: #ffffff;
}
#Nav0_0 .header0-menu > .ant-menu > .kh4sio9paif-editor_css {
	background-color: rgb(144, 0, 0);
	border-top-color: rgba(0, 0, 0, 0);
	border-right-color: rgba(0, 0, 0, 0);
	border-left-color: rgba(0, 0, 0, 0);
}
#Nav0_0 .ant-menu-submenu > .ant-menu-submenu-title > .kh4skmlu5t-editor_css {
	box-shadow: none;
}

#Feature7_0.kh4n4pv8s6-editor_css {
}
#Feature4_0.kh4oxta25rk-editor_css {
	background-color: #ffffff;
}
#Pricing1_0 .pricing1-block-box > .pricing1-top-wrapper > .khw3q5bpwdn-editor_css {
	color: #900000;
}
#Pricing1_0 .pricing1-block-box > .pricing1-top-wrapper > .khw3sc2ijqc-editor_css {
	color: #900000;
}
#Pricing1_0 .pricing1-block-box > .pricing1-top-wrapper > .khw3slgi36-editor_css {
	color: #900000;
}
#Pricing1_0 .pricing1-block-box > .pricing1-top-wrapper > .khw3p1mpepo-editor_css {
	display: flex;
}
#Pricing1_0 .ant-row > .ant-col > .khw4jkrwmlh-editor_css {
	border-top-color: #0050ff;
	border-right-color: #0050ff;
	border-bottom-color: #0050ff;
	border-left-color: #0050ff;
}
#Pricing1_0 .pricing1-block-box > .pricing1-button-wrapper > .khw3xu0wl16-editor_css {
	background-color: #0050ff;
}
#Pricing1_0 .ant-col > .pricing1-block-box > .khw4c0knrk9-editor_css {
	background-color: #0050ff;
}
#Pricing1_0 .khwzho5vilu-editor_css {
	background-color: rgba(0, 0, 0, 0);
}
#Pricing1_0 .pricing1-title-h1 {
	color: #0050ff;
}
