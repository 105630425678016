@content1: content1;
.@{content1}-wrapper {
	.@{content1} {
		height: 100%;
		padding: 0 24px;
		&-img {
			width: 100%;
			transform-origin: top;
			padding: 0 32px;
			display: flex;
			align-items: center;
			justify-content: center;
			span {
				width: 100%;
				display: block;
				img {
					display: block;
				}
			}
		}
		&-text {
			padding: 0 32px;
			height: 100%;
			.@{content1}-content,
			.@{content1}-title {
				position: relative !important;
			}
			.@{content1}-title {
				font-size: 32px;
				font-weight: normal;
				color: #0050ff;
				margin-top: 120px;
			}
			.content {
				margin-top: 20px;
			}
		}
	}
}
